import './app.scss';
import 'react-toastify/dist/ReactToastify.css';

import React, {useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import {QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';

import queryClient from './utils/queryClientUtil';
import {SideBar} from './components/navigation/menu/sidebar.component';
import Router from './routes/routes';
import {getToken} from 'firebase/messaging';
import {messaging} from './firebase/firebaseConfig';

function App() {
  // const {} = useAppSelector(state => state)
  const methods = useForm();

  const {REACT_APP_VAPID_KEY} = process.env;
  // async function requestPermission() {
  //   //requesting permission using Notification API
  //   const permission = await Notification.requestPermission();

  //   if (permission === 'granted') {
  //     const token = await getToken(messaging, {
  //       vapidKey: REACT_APP_VAPID_KEY,
  //     });

  //     //We can send token to server
  //     console.log('Token generated : ', token);
  //   } else if (permission === 'denied') {
  //     //notifications are blocked
  //     alert('You denied for the notification');
  //   }
  // }

  // useEffect(() => {
  //   requestPermission();
  // }, []);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <SideBar />
          <div>
            <Router></Router>
          </div>
          <ToastContainer />
          <ReactQueryDevtools />
        </LocalizationProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
