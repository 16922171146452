import React, {useEffect, useState} from 'react';
import {MealDTO} from '../../../../models/mealModel';
import {useAppDispatch} from '../../../../hooks/redux';
import {MealComponent} from '../mealComponent/mealComponent';
import classes from './mealsManager.module.scss';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBowlFood,
  faCircleInfo,
  faCopy,
  faEdit,
  faTimeline,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import classColor from '../../../shared/sharedColorConfigurations.module.scss';
import ConfirmModal from '../../../common/modal/confirmAction.component';
import {copyMeal, deleteMeal, updateMeal} from '../../../../actions/meals/meal.action';
import TotalMealDayComponent from '../totalMealDayComponent/totalMealDay.component';
import {UserInfoDTO} from '../../../../models/accountModel';
import {userInfo} from 'os';
import {createTotals} from '../../../../utils/mealUtil';
import {AddEditFoodMealComponent} from '../../foodMeals/addEditFoodMeal/AddEditFoodMealComponent';
import ModalWrapper from '../../../common/modal/modalWrapper.component';
import AddEditMealComponent from '../addEditMealComponent/addEditMealComponent';
import {selectCurrentUserId} from '../../../../store/account/slices/login.slice';
import {useSelector} from 'react-redux';
import classesShared from '../../../shared/sharedComponent.module.scss';
import {shortCommonTimeFormat} from '../../../../constants/common/dateFormat';
import {
  CreateMealObject,
  CreateUpdateFoodMealObject,
} from '../../../../models/strict/mealStrictModel';
export interface MealsManagerProperties {
  list: MealDTO[];
  user: UserInfoDTO;
  weight?: number | undefined;
  isShordivate?: boolean;
  onAddMealHandler: () => void;
  callRefresh: () => void;
}
export default function MealsManager({
  list,
  user,
  weight,
  callRefresh,
  onAddMealHandler,
}: MealsManagerProperties) {
  const dispatch = useAppDispatch();
  const userId = useSelector(selectCurrentUserId);
  const [meals, setMeals] = useState<MealDTO[]>(list);
  const [modMeal, setModMeal] = useState<MealDTO>();
  const columns = 4; // Adjust this based on your desired column count
  const placeholdersNeeded = Math.max(0, columns - (meals.length % columns) - 1);
  const [totalCalories, setTotalCallories] = useState<number>();
  const [eatedCalories, setEatedCallories] = useState<number>();
  const [deletedMealId, setDeletedMealId] = useState<string>();
  useEffect(() => {
    if (meals) {
      {
        setEatedCallories(
          Math.round(
            meals
              .filter(
                f =>
                  f.eatTime != null &&
                  DateTime.fromISO(f.eatTime).startOf('day') == DateTime.now().startOf('day'),
              )
              .reduce((accumulator, object) => {
                return accumulator + object.calories!;
              }, 0),
          ),
        );
      }
    }
  }, [meals]);
  useEffect(() => {
    setMeals(list);
  }, [list]);
  const copyMealClickHandler = (mealId: string) => {
    dispatch(copyMeal(mealId)).then(() => {
      callRefresh();
    });
  };
  const {t} = useTranslation();
  function renderMealList() {
    return (
      <>
        <ScrollableBody className={classes.customScrollable}>
          <ModalWrapper
            handleClose={function () {
              setModMeal(undefined);
            }}
            isOpen={modMeal != null}>
            <AddEditMealComponent
              meal={modMeal!}
              saveHandler={function (meal: MealDTO) {
                const requestDTO = new CreateMealObject(
                  meal.id,
                  meal.userId,
                  meal.eatTime,
                  meal.foods?.map(
                    f =>
                      new CreateUpdateFoodMealObject(
                        f.id,
                        f.mealId,
                        f.food?.id ? f.food.id : undefined,
                        f.weight,
                      ),
                  ),
                );
                dispatch(updateMeal(requestDTO)).then(f => {
                  setModMeal(undefined);
                  callRefresh();
                });
              }}
            />
          </ModalWrapper>
          {meals.map((meal, mealIndex) => (
            <li key={meal.id} className={classes.mealInfo}>
              <div>
                {meal.eatTime && DateTime.fromISO(meal.eatTime).toFormat(shortCommonTimeFormat)}
              </div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {meal.calories != null && !isNaN(meal.calories) && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('ccal')}</div>
                    <div>{Math.floor(meal.calories)}</div>
                  </div>
                )}
                {meal.carbohydrates != null && !isNaN(meal.carbohydrates) && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('carb')}</div>
                    <div>{Math.floor(meal.carbohydrates)}</div>
                  </div>
                )}
                {meal.proteins != null && !isNaN(meal.proteins) && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('protein')}</div>
                    <div>{Math.floor(meal.proteins)}</div>
                  </div>
                )}
                {meal.fats != null && !isNaN(meal.fats) && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('fat')}</div>
                    <div>{Math.floor(meal.fats)}</div>
                  </div>
                )}
              </div>

              <div className={classes.infoPanel}>
                <div className={classes.infoWrapper}>
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className={classes.mealInfoIcon + ' mx-2'}></FontAwesomeIcon>
                  <div className={classes.infoContainer}>
                    <div key={meal.id || mealIndex} className="meal">
                      {meal.foods && meal.foods.length > 0 ? (
                        <ul
                          style={{
                            padding: '0',
                            display: 'flex',
                            flexDirection: 'column',
                            width: 'fit-content',
                            minWidth: '120px',
                            textAlign: 'center',
                          }}>
                          {meal.foods.map((foodMeal, foodIndex) => (
                            <li className={classes.mealsFood} key={foodMeal.food?.id || foodIndex}>
                              {foodMeal.food?.name || 'Unknown Food'}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>{t('NoFoodsInThisMeal')}</p>
                      )}
                    </div>
                  </div>
                </div>
                <FontAwesomeIcon
                  onClick={() => {
                    setModMeal(meal);
                  }}
                  icon={faEdit}
                  className={classes.mealInfoIcon + ' mx-2'}></FontAwesomeIcon>
                <FontAwesomeIcon
                  onClick={() => {
                    if (meal.id != null) {
                      copyMealClickHandler(meal.id);
                    }
                  }}
                  icon={faCopy}
                  className={classes.mealInfoIcon + ' mx-2'}></FontAwesomeIcon>
                <FontAwesomeIcon
                  onClick={() => {
                    setDeletedMealId(meal.id);
                  }}
                  icon={faXmarkCircle}
                  className={classes.mealInfoIcon + ' mx-2'}></FontAwesomeIcon>
              </div>
            </li>
          ))}
        </ScrollableBody>
      </>
    );
  }

  return (
    <>
      <ConfirmModal
        handleAgree={function () {
          if (deletedMealId != null) {
            dispatch(deleteMeal(deletedMealId)).then(f => {
              callRefresh();
            });
          }
        }}
        handleClose={function (): void {
          setDeletedMealId(undefined);
        }}
        isOpen={deletedMealId != undefined}></ConfirmModal>
      <h4 className={classColor.sidebarColor + ' my-2'} style={{textAlign: 'center'}}>
        {t('MealsForToday')}
      </h4>

      <div
        className={
          list && list.length > 0 && weight
            ? 'blockGreyWrapper col-lg-12 col-xl-5'
            : 'blockGreyWrapper col-lg-12'
        }>
        <div className="mb-4">
          <a className={classesShared.historicWrapper} href={'./history/' + userId}>
            <FontAwesomeIcon width={30} height={30} icon={faTimeline}></FontAwesomeIcon>
          </a>
        </div>
        <ul className={classes.mealsWrapper}>
          {list && list.length > 0 && renderMealList()}
          <li onClick={onAddMealHandler} className={classes.mealAdd + ' my-4'}>
            <span
              style={{fontSize: '24px', width: '100%', maxWidth: '100%'}}
              className={classes.addIcon + ' my-3'}>
              +
            </span>
          </li>
        </ul>
      </div>
      {weight && list.length > 0 && (
        <div className="blockGreyWrapper col-lg-12  col-xl-5">
          <TotalMealDayComponent
            list={list}
            total={createTotals(user, weight)}></TotalMealDayComponent>
        </div>
      )}
    </>
  );
}
