import React from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectCurrentUserToken} from '../store/account/slices/login.slice';
import axios from '../axios';
export interface ProtectedProperties {
  redirectPath?: string;
  component: React.ComponentType<{id?: string}>;
  id?: string;
}

const AuthGuard: React.FC<ProtectedProperties> = ({
  redirectPath = '/signin',
  component: RouteComponent,
  id,
}) => {
  const user = useSelector(selectCurrentUserToken);
  const params = useParams<{id: string}>();

  // Use the id from props or fallback to the id from params
  const routeId = id ?? params.id;

  if (user) {
    return <RouteComponent id={routeId} />;
  }
  return <Navigate to={redirectPath} replace />;
};

export default AuthGuard;
