import {CreateMealDTO, MealDTO} from '../../models/mealModel';
import {AppDispatch} from '../../store';
import axios from '../../axios';
import {mealSlice} from '../../store/meals/slices/meal.slice';
import {AuthApi} from './constants';

export const MEAL_API_URL = AuthApi.MealApiPrefix + '/meals';
export const getMeals = (
  from?: string,
  userId?: string,
  to?: string,
  options?: {signal?: AbortSignal},
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<MealDTO[]>(MEAL_API_URL + '/filter', {
        from: from,
        to: to,
        userId: userId,
      });
      if (response.status >= 300) {
        dispatch(mealSlice.actions.mealFetchingError);
      } else {
        console.log(response.data);
        dispatch(mealSlice.actions.mealFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};

export const getRecentMeals = async (
  filter: {
    userId: string;
    currentPage?: number;
    pageSize?: number;
  },
  options?: {signal?: AbortSignal},
) => {
  try {
    const response = await axios.post(
      MEAL_API_URL + '/previousMeals',
      {
        paginationRequest: {
          currentPage: filter.currentPage || 0,
          pageSize: filter.pageSize || 10,
        },

        userId: filter.userId,
      },
      {signal: options?.signal},
    );

    if (response.status >= 200 && response.status < 300) {
      console.log('Meals fetched successfully:', response.data);
      return response.data;
    } else {
      console.error('Error fetching meals:', response.status);
      return null;
    }
  } catch (error) {
    console.error('Error in request:', error);
    return null;
  }
};
export const deleteMeal = (mealId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete(MEAL_API_URL, {
        params: {
          mealId,
        },
      });

      // if (response.status >= 300) {
      //   dispatch(mealSlice.actions.mealFetchingError)
      // } else {
      //   dispatch(mealSlice.actions.mealFetchingSuccess(response.data))
      // }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const copyMeal = (mealId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post(MEAL_API_URL + '/copyMeal?MealId=' + mealId);
      if (response.status >= 300) {
        throw new Error(`Failed to add food, status code: ${response.status}`);
      }
      return response.data;
    } catch (e) {
      console.log('Error adding food', e);
      throw e;
    }
  };
};
export const deleteFoodMeal = (mealId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete('api/mealsFood', {
        params: {
          mealId,
        },
      });

      // if (response.status >= 300) {
      //   dispatch(mealSlice.actions.mealFetchingError)
      // } else {
      //   dispatch(mealSlice.actions.mealFetchingSuccess(response.data))
      // }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addMeals = (meal: CreateMealDTO) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<MealDTO[]>(MEAL_API_URL, meal);
      if (response.status >= 300) {
        dispatch(mealSlice.actions.mealFetchingError);
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const updateMeal = (meal: MealDTO) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put<MealDTO[]>(MEAL_API_URL, meal);
      if (response.status >= 300) {
        dispatch(mealSlice.actions.mealFetchingError);
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
